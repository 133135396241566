@import url('./font.css');

* {
    box-sizing:border-box;
    margin:0;
    padding:0;
    overflow: visible;
    font-family: 'Source Serif Pro', serif;
}

a {
  text-decoration: none;
}

::selection {
  background: #90006A; /* WebKit/Blink Browsers */
  color: transparent;
  text-shadow: 0 0 2px white;
}

::-moz-selection {
  background: #90006A; /* Gecko Browsers */
  color: transparent;
  text-shadow: 0 0 2px white;
}

.betterimpres {
  padding: 4px 4px 4px 4px;
  border:3px solid #90006A;
  border-radius: 20px;
}

.betterimpres:hover
{
  text-shadow: 10 10 2px white;
  animation-name: skitzlink;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  color: white;
}

@keyframes skitzlink {
  0%   {text-shadow: -5px 3px 1px white;}
  100% {text-shadow: -5px 1px 20px white;color: white; background-color:#90006A;}
}

p {
  color:black;
}

.parentwrap{
  width:100vw;
  height:100vh;
  background-color: black;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.bgweirdtext {
  position:fixed;
 font-family: 'Radley', serif;
 cursor: default;
 color : #90006A;
 width: 100%;
 text-align:center;
 /* Starting position */
 -moz-transform:translateX(100%);
 -webkit-transform:translateX(100%);
 transform:translateX(100%);
 /* Apply animation to this element */
}
/* Move it (define the animation) */
@-moz-keyframes parentwrap {
 0%   { -moz-transform: translateX(100%); }
 100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes parentwrap {
 0%   { -webkit-transform: translateX(100%); }
 100% { -webkit-transform: translateX(-100%); }
}
@keyframes parentwrap {
 0%   {
 -moz-transform: translateX(100%); /* Firefox bug fix */
 -webkit-transform: translateX(100%); /* Firefox bug fix */
 transform: translateX(100%);
 }
 100% {
 -moz-transform: translateX(-100%); /* Firefox bug fix */
 -webkit-transform: translateX(-100%); /* Firefox bug fix */
 transform: translateX(-100%);
 }
}

.bgweirdtextbw {
  position:fixed;
 font-family: 'Radley', serif;
 cursor: default;
 color : #90006A;
 width: 100%;
 text-align:center;
 /* Starting position */
 -moz-transform:translateX(-100%);
 -webkit-transform:translateX(-100%);
 transform:translateX(-100%);
 /* Apply animation to this element */
}
/* Move it (define the animation) */
@-moz-keyframes parentwrapbw {
 0%   { -moz-transform: translateX(-100%); }
 100% { -moz-transform: translateX(100%); }
}
@-webkit-keyframes parentwrapbw {
 0%   { -webkit-transform: translateX(-100%); }
 100% { -webkit-transform: translateX(100%); }
}
@keyframes parentwrapbw {
 0%   {
 -moz-transform: translateX(-100%); /* Firefox bug fix */
 -webkit-transform: translateX(-100%); /* Firefox bug fix */
 transform: translateX(-100%);
 }
 100% {
 -moz-transform: translateX(100%); /* Firefox bug fix */
 -webkit-transform: translateX(100%); /* Firefox bug fix */
 transform: translateX(100%);
 }
}

.parent {
  max-width: 1920px;
  height:900px;
  margin-left:calc(50% - 960px);
}

.bodyfake {
  height:100%;
  width:100%;
  background-color: black;
}

.flexbody {
  width:100%;
  height:84vh;
  font-size:300px;
  display:flex;
  position:relative;

}

/* headlinebox details, see /components/headlinebox.js*/

.headline {
  width:100%;
  height:10vh;
}

.headlineboxwrap {
  width:100%;
  height:100%;
  display:flex;
  padding-top:1%;
  align-items: center;
  justify-content: center;
}

.headlinebox{
  padding:5px 5px 5px 5px;
  background-color: white;
  border: double 10px #000;
  z-index:10;
}

.headlinetext{
  font-size:2.5em;
  text-align: center;
  color:black;
}

/* titlebox details, see /components/detailbox.js*/

.leftbox{
  flex:2;
}


.textbox {
  transform: rotate(-90deg) ;
  margin-left:calc(50% - 700px);
  margin-top:calc(50% - 70px);
}

.titleitselfwrap {
}

.titleitself {
  height:auto;
  width:1em;
}

.bigtitletext {
  font-family: 'Radley', serif;
  font-size: 0.8em;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  cursor: default;
  color:white;
  margin-left: 40%;
  white-space: nowrap;
  transform: translateX(-180%) translateY(87%) scaleY(2.3) scaleX(0.82);
  animation: 1s ease-out 0s 1 rotategetgot;
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-180%) translateY(87%);
  }
  100% {
    transform: translateX(-180%) translateY(87%) scaleY(2.3) scaleX(0.82);
  }
}

.bigtoolbox {
display:flex;
  flex:9;
}

/* middlebox details, see /components/detailbox.js*/
.middlebox{
  height:100%;
  flex:6;
  display:flex;
  align-items: center;
  justify-content: center;
}

.contextbox {
  background-color:white;
  height:96%;
  width:96%;
  display:flex;
  align-items: center;
  justify-content: center;
  border: double 18px #000;
  flex-direction: column;
}

.contentinside {
  display:flex;
  height:100%;
  width:100%;
  display:flex;
  flex-direction: column;

}

.changecontentbox{
  flex:7;
  flex-direction: column;
  display:flex;
}

.detheaderbox {
  flex:1;
  flex:2.5;
  display:flex;
  flex-direction: row;
}

.namebox{
  width:100%;
  display:flex;
  padding-left:5%;

}

.thename{
  font-family: 'Radley', serif;
  font-size: 0.55em;
  line-height: 1em;
  text-align: left;
  cursor: default;
}

.detcontextbox {
  flex:8;
  display: flex;
  flex-direction: row;
  z-index:100000000000;
  align-items: center;
  justify-content: center;
}

.detlinksbox {
  flex:2;
  flex-direction: row;
  display: flex;
  z-index:10000000000;
}

.portfoliodetwrap {
  display:flex;
  flex-direction: column;
  height:100%;
}

.portfoliolistwrap {
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
}

.portfoliooption {
  flex:2;
  display:flex;
  flex-direction: row;
}

.portfolioopttitle {
  cursor:pointer;
}

.porttitle{
  font-size: 0.14em;
  color : #90006A;
  font-weight:bold;
}

.porttitle:hover
{
  text-shadow: 10 10 2px white;
  font-color: white;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #90006A;
}

.portfoliooptdate {
  padding-top:4%;
  cursor:pointer;
}

.portdate{
  font-size: 0.08em;
  color : #90006A;
}

.portfoliosmalldetwrap {
  width:50%;
  flex-direction: column;
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
}

.portdescwrap {
  flex:3;
  width:95%;
  height:95%;
}

.portdesc {
  width:100%;
  height:100%;
}

.portdesctitle {
  font-family: 'Radley', serif;
  text-align:center;
  cursor: default;
  font-size:0.17em;
  text-decoration: underline;
}

.portdesctext {
  font-size:0.1em;
  cursor: default;
  text-align:justify;
}

.portlinkwrap {
  flex:1;
  width:95%;
  height:95%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.pagecover {
  flex:1;
}

.pagesarea{
  width:100%;
}

.pagetext {
  font-size: 0.14em;
  color : #90006A;
  font-weight:bold;
  display:flex;
  align-items: center;
  justify-content: center;
}

.pagetext span{
  display:flex;
  align-items: center;
  justify-content: center;
  flex:1;
  cursor: pointer;
}

.pagetext span:hover{
  text-shadow: 10 10 2px white;
  font-color: white;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #90006A;
}

.aptitbox {
  display:flex;
  width:100%;
  height:100%;
  flex-direction: column;
  flex:1;

}

.aptitoptionswrap {
  flex:3;
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.aptitoptions {
  width:95%;
  height:95%;
}

.aptitoptions a {
  text-decoration: none;
}

.apatitentry {
  font-size:0.13em;
  color : #90006A;
}

.apatitentrypage {
  font-size:0.13em;
  text-align:center;
  color : #90006A;
}

.aptitcatawarp {
  flex:1;
  display:flex;
  align-items: center;
  justify-content: center;
}

.aptitcata {
  height:95%;
  width:95%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.aptitcatatitle {
  font-size:0.2em;
  text-align: center;
  cursor: pointer;
  color : #90006A;
  font-weight: bold;
  padding: 4px 4px 4px 4px;
  border:2px solid #90006A;
  border-radius: 20px;
}

.aptitcatatitle:hover
{
  text-shadow: 10 10 2px white;
  animation-name: skitzlink;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  color: white;
}

.contact_trial {
  font-size: 0.2em;
}

.detthumbnail {
    color: #90006A;
}

.linktext {
  font-size:0.15em;
  padding: 4px 4px 4px 4px;
  border:2px solid #90006A;
  border-radius: 20px;
  color : #90006A;
  font-weight: bold;
}

.linktext:hover
{
  text-shadow: 10 10 2px white;
  animation-name: skitzlink;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  color: white;
}

.linkboxwrap1 {
  flex:1;
  width:100%;
  height:100%;
}

.linkbox1 {
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./github.png");
  background-size: 90px 90px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox1:hover{
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./githubhover.png");
  background-size: 90px 90px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox1:hover .linkbox1{
  display:none;
}

.linkboxwrap2 {
  flex:1;
}

.linkboxwrap2 {
  flex:1;
  width:100%;
  height:100%;
}

.linkbox2 {
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./linkedin.png");
  background-size: 90px 90px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox2:hover{
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./linkedinhover.png");
  background-size: 90px 90px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkboxwrap3 {
  flex:1;
}

.linkboxwrap3 {
  flex:1;
  width:100%;
  height:100%;
}

.linkbox3 {
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./instagram.png");
  background-size: 90px 90px;;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox3:hover{
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./instagramhover.png");
  background-size: 90px 90px;;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}


.linkboxwrap4 {
  flex:1;
}

.linkboxwrap4 {
  flex:1;
  width:100%;
  height:100%;
}

.linkbox4 {
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./email.png");
  background-size:90px 90px;;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox4:hover{
  flex:1;
  width:90px;
  height:90px;
  background-image: url("./emailhover.png");
  background-size:90px 90px;;
  background-repeat: no-repeat;
  margin-left:calc(50% - 45px);
  margin-top:calc(50% - 90px);
}

.linkbox4:hover .linkbox4{
  display:none;
}

.descriptionone{
  font-size:0.1em;
  line-height: 0.8em;
  text-align:justify;
  cursor: default;
}

/* aboutbox */

.aboutboxwrap {
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.aboutbox {
  width:95%;
  height:95%;
  display:flex;
  align-items: center;
  flex-direction: column;
}

.sitewrap {
  width:95%;
  height:95%;
  display:flex;
  flex-direction: row;
}

.site1 {
  flex:1;
  height:100%;
}

.site1 a {
  text-decoration: none;
}

.siteentry {
  font-size: 0.1em;
  text-align: center;
  color : #90006A;
}

.resumebox {
  width:95%;
  height:95%;
  display:flex;
  align-items: center;
  flex-direction: column;
}

.aboutdescription {
  text-align: justify;
  font-size: 0.12em;
  width:100%;
  cursor: default;
}

.controlCV {display:flex;
flex-direction: row;}

.resumelink {
  text-align: center;
  font-size: 0.14em;
  cursor: pointer;
  flex:1;
  padding: 2px 2px 2px 1px;
  border:3px solid #90006A;
  border-radius: 20px;
}

.resumelink:hover
{
  text-shadow: 10 10 2px white;
  animation-name: skitzlink;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  color: white;
}

.fillresumelink {
  text-align: center;
  font-size: 0.1em;
  cursor: pointer;
  flex:1;
}

.resumedescription {
  text-align: justify;
  font-size: 0.095em;
  width:100%;
  cursor: default;
}

.aboutdescriptionfinal {
  text-align: center;
  font-size: 0.12em;
  width:100%;
  cursor: default;
}

.resumelink {
  text-align: center;
  font-size: 0.12em;
  width:100%;
  cursor: pointer;
}

p br {
   line-height: 50px;
}

/* rightbox details, see /components/projectbox.js*/

.rightbox{
  height:100%;
  flex:2;
}

.projectwrap {
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.projectbox {
  width:95%;
  height:100%;
  flex-direction: column;
  display:flex;
}

.projectitlewrap{
  width:100%;
  margin-bottom: 5px;
}

.thelcock {
  font-size:0.12em;
  line-height: 1em;
  text-align: center;
  text-justify: inter-word;
  color:white;
  cursor:default;
}

.projectswrapper {
  width:100%;
  flex: 1;
  background-color:black;
  transition: 0.4s;
}

.projectswrapper:hover {
  background-color: #90006A;
  opacity: 1;
}

.projectitself {
  height:60%;
}

.theprojectname{
  font-size:0.2em;
  line-height:1em;
  color:white;
  cursor:pointer;
  font-family: 'Radley', serif;
  opacity:1;
}

.projectyears {
  height:40%;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
  cursor:pointer;
  color:white;
  font-family: 'Radley', serif;
}

/* footer details*/

.footer {
  height:5vh;
  width:100%;
}

.footertext {
 overflow: hidden;
 position: relative;
}
.footertext p {
  font-family: 'Radley', serif;
 cursor: default;
 font-size: 4em;
 color: white;
 width: 100%;
 height: 100%;
 margin: 0;
 line-height: 1.1em;
 text-align: center;
 /* Starting position */
 -moz-transform:translateX(100%);
 -webkit-transform:translateX(100%);
 transform:translateX(100%);
 /* Apply animation to this element */
 -moz-animation: footertext 10s linear infinite;
 -webkit-animation: footertext 10s linear infinite;
 animation: footertext 10s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes footertext {
 0%   { -moz-transform: translateX(100%); }
 100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes footertext {
 0%   { -webkit-transform: translateX(100%); }
 100% { -webkit-transform: translateX(-100%); }
}
@keyframes footertext {
 0%   {
 -moz-transform: translateX(100%); /* Firefox bug fix */
 -webkit-transform: translateX(100%); /* Firefox bug fix */
 transform: translateX(100%);
 }
 100% {
 -moz-transform: translateX(-100%); /* Firefox bug fix */
 -webkit-transform: translateX(-100%); /* Firefox bug fix */
 transform: translateX(-100%);
 }
}

@media screen and (max-height: 1000px) {

.parent {
  max-width: 1920px;
  margin-left:calc(50% - 960px);
}

.bigtitletext {
  font-size: 0.75em;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  transform: translateX(-120%) translateY(96%) scaleY(2.4) scaleX(0.75);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-120%) translateY(96%);
  }
  100% {
    transform: translateX(-120%) translateY(96%) scaleY(2.4) scaleX(0.75);
  }
}

.thename{
  font-size: 0.45em;
  line-height: 1em;
}

.porttitle{
  font-size: 0.14em;
}

.portfoliooptdate {
  padding-top:4%;
}

.portdate{
  font-size: 0.08em;
}

.portdesctitle {
  font-size:0.17em;
}

.portdesctext {
  font-size:0.1em;
}

.apatitentry {
  font-size:0.13em;
}

.apatitentrypage {
  font-size:0.13em;
}

.contecttrial {
  font-size: 0.3em;
}

.descriptionone{
  font-size:0.1em;
  line-height: 0.8em;
}

.siteentry {
  font-size: 0.095em;
}

.aboutdescription {
  font-size: 0.11em;
}

.resumedescription {
  font-size: 0.088em;
}

.aboutdescriptionfinal {
  font-size: 0.11em;
}

.controlCV {
  display:flex;
flex-direction: row;
}

.resumelink {
  font-size: 0.14em;
}

.fillresumelink {
  font-size: 0.1em;
}

.projectitlewrap{
  margin-bottom: 5px;
}

.thelcock {
  font-size:0.12em;
}

.theprojectname{
  font-size:0.2em;
}

.theprojectyears{
  font-size:0.1em;
}

.footertext p {
 font-size: 3.5em;
}


@media screen and (max-width: 1900px) {

.parent {
  max-width: 1900px;
  margin-left:calc(50% - 950px);
}

.titleitself {
  width:1em;
}

.bigtitletext {
  font-size: 0.78em;
  transform: translateX(-120%) translateY(100%) scaleY(2) scaleX(0.72);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-120%) translateY(100%);
  }
  100% {
  transform: translateX(-120%) translateY(100%) scaleY(2) scaleX(0.72);
  }
}

.aboutdescription {
  font-size: 0.1em;
}

.aboutdescriptionfinal {
  font-size: 0.1em;
}

.theprojectname{
  font-size:0.18em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.12em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
}

.linkbox1 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox1:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.resumedescription {
  font-size: 0.085em;
}

.resumelink {
  font-size: 0.13em;
}

.thename{
  font-size: 0.4em;
}

}
@media screen and (max-width: 1800px) {

.parent {
  max-width: 1800px;
  margin-left:calc(50% - 900px);
}

.titleitself {
  width:1em;
}

.bigtitletext {
  font-size: 0.74em;
  transform: translateX(-130%) translateY(108%) scaleY(1.95) scaleX(0.76);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-130%) translateY(108%);
  }
  100% {
  transform: translateX(-130%) translateY(108%) scaleY(1.95) scaleX(0.76);
  }
}

.aboutdescription {
  font-size: 0.1em;
}

.aboutdescriptionfinal {
  font-size: 0.1em;
}

.theprojectname{
  font-size:0.16em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.12em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
}

.resumedescription {
  font-size: 0.08em;
}

.resumelink {
  font-size: 0.12em;
}

.thename{
  font-size: 0.4em;
}


}
@media screen and (max-width: 1700px) {

.parent {
  max-width: 1700px;
  margin-left:calc(50% - 850px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.72em;
  transform: translateX(-140%) translateY(110%) scaleY(1.9) scaleX(0.78);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-140%) translateY(110%);
  }
  100% {
  transform: translateX(-140%) translateY(110%) scaleY(1.9) scaleX(0.78);
  }
}

.aboutdescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.1em;
}

.theprojectname{
  font-size:0.165em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.1em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
}

.resumedescription {
  font-size: 0.075em;
}

.thename{
  font-size: 0.4em;
}
}

@media screen and (max-width: 1600px) {

.parent {
  max-width: 1600px;
  margin-left:calc(50% - 800px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-150%) translateY(115%) scaleY(1.9) scaleX(0.8);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-150%) translateY(115%);
  }
  100% {
  transform: translateX(-150%) translateY(115%) scaleY(1.9) scaleX(0.8);
  }
}

.aboutdescription {
  font-size: 0.09em;
}

.aboutdescriptionfinal {
  font-size: 0.1em;
}

.theprojectname{
  font-size:0.16em;
}

.portdesctext {
  font-size:0.083em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.12em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.1em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.08em;
}

.resumelink {
  font-size: 0.13em;

}

.thename{
  font-size: 0.4em;
}

.headlinetext {
  font-size:1.7em;
}

}

@media screen and (max-width: 1500px) {

.parent {
  max-width: 1500px;
  margin-left:calc(50% - 750px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-150%) translateY(115%) scaleY(1.8) scaleX(0.8);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-150%) translateY(115%);
  }
  100% {
  transform: translateX(-150%) translateY(115%) scaleY(1.8) scaleX(0.8);
  }
}

.aboutdescription {
  font-size: 0.09em;
}

.aboutdescriptionfinal {
  font-size: 0.092em;
}

.theprojectname{
  font-size:0.15em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.11em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.06em;
}

.thelcock {
  font-size:0.095em;
}

.resumedescription {
  font-size: 0.078em;
}

.resumelink {
  font-size: 0.12em;
}

.thename{
  font-size: 0.4em;
}

.siteentry {
  font-size: 0.09em;
}

}

@media screen and (max-width: 1400px) {

.parent {
  max-width: 1400px;
  margin-left:calc(50% - 700px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-160%) translateY(117%) scaleY(1.65) scaleX(0.8);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-160%) translateY(117%);
  }
  100% {
  transform: translateX(-160%) translateY(117%) scaleY(1.65) scaleX(0.8);
  }
}

.aboutdescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.theprojectname{
  font-size:0.14em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.1em;
}

.portdate{
  font-size: 0.06em;
}

.portdesctitle {
  font-size:0.14em;
}

.portdesctext {
  font-size:0.076em;
}

.thelcock {
  font-size:0.09em;
}

.resumedescription {
  font-size: 0.07em;
}

.resumelink {
  font-size: 0.11em;
}
.thename{
  font-size: 0.4em;
}

.apatitentry {
  font-size:0.12em;
}

.siteentry {
  font-size: 0.085em;
}

}

@media screen and (max-width: 1300px) {

.parent {
  max-width: 1300px;
  margin-left:calc(50% - 650px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-160%) translateY(126%) scaleY(1.75) scaleX(0.85);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-160%) translateY(126%);
  }
  100% {
  transform: translateX(-160%) translateY(126%) scaleY(1.75) scaleX(0.85);
  }
}

.aboutdescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.125em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.09em;
}

.portdate{
  font-size: 0.05em;
}

.portdesctitle {
  font-size:0.14em;
}

.portdesctext {
  font-size:0.08em;
}

.thelcock {
  font-size:0.085em;
}

.resumedescription {
  font-size: 0.065em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.35em;
}

.siteentry {
  font-size: 0.08em;
}

.apatitentry {
  font-size:0.105em;
}

.apatitentrypage {
  font-size:0.105em;
}

}

@media screen and (max-width: 1200px) {

.contecttrial {
  font-size:0.2em;
}

.parent {
  max-width: 1200px;
  margin-left:calc(50% - 600px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-160%) translateY(125%) scaleY(1.8) scaleX(0.85);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-160%) translateY(125%);
  }
  100% {
   transform: translateX(-160%) translateY(125%) scaleY(1.8) scaleX(0.85);
  }
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.085em;
}

.theprojectname{
  font-size:0.11em;
  line-height:1em
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.portfoliooption {
  flex-direction: column;
}

.porttitle{
  font-size: 0.08em;
  line-height: 0.08em
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.12em;
}

.portdesctext {
  font-size:0.07em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.06em;
}

.resumelink {
  font-size: 0.1em;

}
.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.11em;
}

}

@media screen and (max-width: 1100px) {

.parent {
  max-width: 1100px;
  margin-left:calc(50% - 550px);
}

.flexbody {
  display: flex;
}

.leftbox{
  display:none;
}

.bigtoolbox {
  display:flex;
  flex:1;
  flex-direction: row;
}

.headlinetext{
  font-size:1.6em;
}

.middlebox {
  padding-left:5%;
  height:100%;
  flex:2;
}

.rightbox {
  height:100%;
  flex:1;
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.075em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.07em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.06em;
}

.resumelink {
  font-size: 0.11em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.11em;
}

.apatitentrypage {
  font-size:0.11em;
}

}


@media screen and (max-width: 1000px) {
.parent {
  max-width: 1000px;
  margin-left:calc(50% - 500px);
}

.headlinetext{
  font-size:1.6em;
}

.middlebox {
  padding-left:5%;
}

.rightbox {
  height:100%;
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.075em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.065em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.06em;
}

.resumelink {
  font-size: 0.11em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.aptitcatatitle {
  font-size:0.15em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.06em;
}

}

@media screen and (max-width: 900px) {
.parent {
  max-width: 900px;
  margin-left:calc(50% - 450px);
}

.headlinetext{
  font-size:1.6em;
}

.aboutdescription {
  font-size: 0.065em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.13em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.063em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.1em;

}
.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.aptitcatatitle {
  font-size:0.15em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.05em;
}

}

@media screen and (max-width: 800px) {
.parent {
  max-width: 800px;
  margin-left:calc(50% - 400px);
}

.headlinetext{
  font-size:1.6em;
}

.aboutdescription {
  font-size: 0.06em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.11em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.09em;
}

.portdesctext {
  font-size:0.06em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.053em;
}

.resumelink {
  font-size: 0.09em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.05em;
}

}

@media screen and (max-width: 700px) {
.parent {
  max-width: 700px;
  margin-left:calc(50% - 350px);
}

.headlinetext{
  font-size:1.3em;
}

.middlebox {
  padding-left:6%;
}

.aboutdescription {
  font-size: 0.055em;
}

.aboutdescriptionfinal {
  font-size: 0.055em;
}

.theprojectname{
  font-size:0.09em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.08em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linkbox1 {
  flex:1;
  width:60px;
  height:60px;
  background-image: url("./github.png");
  background-size: 60px 60px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox1:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.resumedescription {
  font-size: 0.05em;
}

.resumelink {
  font-size: 0.09em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.045em;
}

}

@media screen and (max-width: 600px) {

.parent {
  max-width: 600px;
  margin-left:calc(50% - 300px);
}

.headlinetext{
  font-size:1em;
}

.aboutdescription {
  font-size: 0.05em;
}

.middlebox{
  margin-left:3%;
}

.aboutdescriptionfinal {
  font-size: 0.055em;
}

.theprojectname{
  font-size:0.085em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.07em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.08em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.045em;
}

.resumelink {
  font-size: 0.09em;
}

.thename{
  font-size: 0.2em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.065em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.035em;
}
}

@media screen and (max-width: 500px) {
.parent {
  max-width: 500px;
  margin-left:calc(50% - 250px);
}

.headlinetext{
  font-size:1em;
}

.aboutdescription {
  font-size: 0.042em;
}

.aboutdescriptionfinal {
  font-size: 0.045em;
}

.theprojectname{
  font-size:0.07em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.05em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linkbox1 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox1:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.resumedescription {
  font-size: 0.035em;
}

.linktext {
  font-size: 0.07em
}

.resumelink {
  font-size: 0.07em;
}

.thename{
  font-size: 0.12em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.025em;
}

.apatitentry {
  font-size:0.05em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.06em;
}

}

@media screen and (max-width: 400px) {
.parent {
  max-width: 400px;
  margin-left:calc(50% - 200px);
}

.headlinetext{
  font-size:1em;
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.045em;
}

.theprojectname{
  font-size:0.06em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.04em;
}

.portdate{
  font-size: 0.03em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.035em;
}

.linktext {
  font-size: 0.07em
}

.resumelink {
  font-size: 0.04em;
}

.thename{
  font-size: 0.1em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.02em;
}

.apatitentry {
  font-size:0.04em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.04em;
}

}

}

@media screen and (max-height: 900px) {

.parent {
  max-width: 1920px;
  margin-left:calc(50% - 960px);
}

.headlinetext{
  font-size:2.3em;
}

.textbox {
  transform: rotate(-90deg) ;
  margin-left:calc(50% - 700px);
  margin-top:calc(50% - 70px);
}

.titleitself {
  width:1.1em;
}

.bigtitletext {
  font-size: 0.7em;
  -webkit-text-stroke-width: 3px;
  transform: translateX(-80%) translateY(105%) scaleY(2.55) scaleX(0.73);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-80%) translateY(105%);
  }
  100% {
    transform: translateX(-80%) translateY(105%) scaleY(2.55) scaleX(0.73);
  }
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.porttitle{
  font-size: 0.14em;
}

.portdate{
  font-size: 0.08em;
}

.portdesctitle {
  font-size:0.17em;
}

.portdesctext {
  font-size:0.095em;
}

.apatitentry {
  font-size:0.11em;
}

.apatitentrypage {
  font-size:0.13em;
}

.aptitcatatitle {
  font-size:0.2em;
}

.contecttrial {
  font-size: 0.3em;
}

.linktext {
  font-size:0.15em;
}

.descriptionone{
  font-size:0.1em;
  line-height: 0.8em;
}

.siteentry {
  font-size: 0.085em;
}

.aboutdescription {
  font-size: 0.11em;
}

.resumedescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.11em;
}

.controlCV {display:flex;
flex-direction: row;}

.resumelink {
  font-size: 0.14em;
}

.fillresumelink {
  font-size: 0.1em;
}

.thelcock {
  font-size:0.12em;
}

.theprojectname{
  font-size:0.2em;
}

.theprojectyears{
  font-size:0.1em;
}

.footertext p {
 font-size: 3em;
}

@media screen and (max-width: 1900px) {
.parent {
  max-width: 1900px;
  margin-left:calc(50% - 950px);
}

.titleitself {
  width:1.5em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-58%) translateY(100%) scaleY(3.1) scaleX(0.73);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-58%) translateY(100%);
  }
  100% {
  transform: translateX(-58%) translateY(100%) scaleY(3.1) scaleX(0.73);
  }
}

.aboutdescription {
  font-size: 0.1em;
}

.aboutdescriptionfinal {
  font-size: 0.1em;
}

.theprojectname{
  font-size:0.18em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.12em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
  line-height: 1em;
}

.linkbox1 {
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./github.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox1:hover{
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./githubhover.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2 {
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./linkedin.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2:hover{
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./linkedinhover.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3 {
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./instagram.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3:hover{
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./instagramhover.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4 {
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./email.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4:hover{
  flex:1;
  width:80px;
  height:80px;
  background-image: url("./emailhover.png");
  background-size: 80px 80px;
  background-repeat: no-repeat;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.resumedescription {
  font-size: 0.08em;
}

.resumelink {
  font-size: 0.13em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}
}
@media screen and (max-width: 1800px) {
.parent {
  max-width: 1800px;
  margin-left:calc(50% - 900px);
}

.titleitself {
  width:1.5em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-55%) translateY(110%) scaleY(3.1) scaleX(0.77);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-55%) translateY(110%);
  }
  100% {
  transform: translateX(-55%) translateY(110%) scaleY(3.1) scaleX(0.77);
  }
}

.aboutdescription {
  font-size: 0.09em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.theprojectname{
  font-size:0.18em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.12em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.073em;
}

.resumelink {
  font-size: 0.15em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

}
@media screen and (max-width: 1700px) {
.parent {
  max-width: 1700px;
  margin-left:calc(50% - 850px);
}

.titleitself {
  width:1.2em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-80%) translateY(118%) scaleY(2.5) scaleX(0.8);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-80%) translateY(118%);
  }
  100% {
    transform: translateX(-80%) translateY(118%) scaleY(2.5) scaleX(0.8);
  }
}

.aboutdescription {
  font-size: 0.09em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.theprojectname{
  font-size:0.16em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.12em;
}

.portdesctext {
  font-size:0.078em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.075em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}
}

@media screen and (max-width: 1600px) {
.parent {
  max-width: 1600px;
  margin-left:calc(50% - 800px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-115%) translateY(125%) scaleY(1.9) scaleX(0.77);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-115%) translateY(125%);
  }
  100% {
  transform: translateX(-115%) translateY(125%) scaleY(1.9) scaleX(0.77);
  }
}

.aboutdescription {
  font-size: 0.09em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.portdesctext {
  font-size:0.08em;
}

.portdesctitle {
  font-size:0.15em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.12em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.1em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.07em;
}

.resumelink {
  font-size: 0.12em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}
}

@media screen and (max-width: 1500px) {
.parent {
  max-width: 1500px;
  margin-left:calc(50% - 750px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-110%) translateY(117%) scaleY(1.8) scaleX(0.72);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-110%) translateY(117%);
  }
  100% {
  transform: translateX(-110%) translateY(117%) scaleY(1.8) scaleX(0.72);
  }
}

.aboutdescription {
  font-size: 0.085em;
}

.aboutdescriptionfinal {
  font-size: 0.085em;
}

.theprojectname{
  font-size:0.14em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.11em;
}

.portdesctitle {
  font-size:0.13em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.06em;
}

.thelcock {
  font-size:0.095em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.074em;
}

.resumelink {
  font-size: 0.12em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.08em;
}

}

@media screen and (max-width: 1400px) {
.parent {
  max-width: 1400px;
  margin-left:calc(50% - 700px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-120%) translateY(117%) scaleY(1.65) scaleX(0.71);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-120%) translateY(117%);
  }
  100% {
    transform: translateX(-120%) translateY(117%) scaleY(1.65) scaleX(0.71);
  }
}

.aboutdescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.13em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.1em;
}

.portdate{
  font-size: 0.06em;
}

.portdesctitle {
  font-size:0.12em;
}

.portdesctext {
  font-size:0.074em;
}

.thelcock {
  font-size:0.09em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.065em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.08em;
}

.headlinetext {
  font-size:2em;
}

}

@media screen and (max-width: 1300px) {
.parent {
  max-width: 1300px;
  margin-left:calc(50% - 650px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-125%) translateY(126%) scaleY(1.75) scaleX(0.77);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-125%) translateY(126%);
  }
  100% {
    transform: translateX(-125%) translateY(126%) scaleY(1.75) scaleX(0.77);
  }
}

.aboutdescription {
  font-size: 0.075em;
}

.aboutdescriptionfinal {
  font-size: 0.075em;
}

.theprojectname{
  font-size:0.12em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.09em;
}

.portdesctitle {
  font-size:0.12em;
}

.portdate{
  font-size: 0.05em;
}

.portdesctitle {
  font-size:0.12em;
}

.portdesctext {
  font-size:0.075em;
}

.thelcock {
  font-size:0.085em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.065em;
}

.resumelink {
  font-size: 0.085em;
}

.thename{
  font-size: 0.35em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.075em;
}

.apatitentry {
  font-size:0.105em;
}

.apatitentrypage {
  font-size:0.105em;
}

}

@media screen and (max-width: 1200px) {
.contecttrial {
  font-size:0.2em;
}

.parent {
  max-width: 1200px;
  margin-left:calc(50% - 600px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-125%) translateY(125%) scaleY(1.8) scaleX(0.77);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-125%) translateY(125%);
  }
  100% {
    transform: translateX(-125%) translateY(125%) scaleY(1.8) scaleX(0.77);
  }
}

.aboutdescription {
  font-size: 0.065em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.11em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.portfoliooption {
  flex-direction: column;
}

.porttitle{
  font-size: 0.08em;
  line-height: 0.08em
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.07em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.058em;
}

.resumelink {
  font-size: 0.09em;

}
.thename{
  font-size: 0.35em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.apatitentrypage {
  font-size:0.11em;
}
}

@media screen and (max-width: 1100px) {
.parent {
  max-width: 1100px;
  margin-left:calc(50% - 550px);
}

.leftbox{
  display:none;
}

.bigtoolbox {
  display:flex;
  flex:1;
  flex-direction: row;
}

.headlinetext{
  font-size:1.6em;
}

.middlebox {
  padding-left:5%;
  height:100%;
  flex:2;
}

.rightbox {
  height:100%;
  flex:1;
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.07em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.057em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.4em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.apatitentrypage {
  font-size:0.11em;
}

}


@media screen and (max-width: 1000px) {
.parent {
  max-width: 1000px;
  margin-left:calc(50% - 500px);
}

.headlinetext{
  font-size:1.6em;
}

.middlebox {
  padding-left:5%;
}

.aboutdescription {
  font-size: 0.06em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.065em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.11em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.aptitcatatitle {
  font-size:0.15em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.06em;
}

.headlinetext{
  font-size:1.2em;
}

}

@media screen and (max-width: 900px) {
.parent {
  max-width: 900px;
  margin-left:calc(50% - 450px);
}

.headlinetext{
  font-size:1.6em;
}

.aboutdescription {
  font-size: 0.065em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.13em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.11em;
}

.portdesctext {
  font-size:0.063em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.aptitcatatitle {
  font-size:0.15em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.05em;
}

}


@media screen and (max-width: 800px) {
.parent {
  max-width: 800px;
  margin-left:calc(50% - 400px);
}

.headlinetext{
  font-size:1.6em;
}

.aboutdescription {
  font-size: 0.05em;
}

.aboutdescriptionfinal {
  font-size: 0.055em;
}

.theprojectname{
  font-size:0.11em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.085em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linktext {
  font-size:0.1em;
}

.resumedescription {
  font-size: 0.05em;
}

.resumelink {
  font-size: 0.07em;
}

.thename{
  font-size: 0.25em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.045em;
}
}

@media screen and (max-width: 700px) {
.parent {
  max-width: 700px;
  margin-left:calc(50% - 350px);
}

.headlinetext{
  font-size:1.3em;
}

.middlebox {
  padding-left:6%;
}

.rightbox {
  height:100%;
}

.aboutdescription {
  font-size: 0.05em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

.theprojectname{
  font-size:0.075em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.08em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.07em;
  line-height: 1em;
}

.linkbox1 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox1:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.resumedescription {
  font-size: 0.045em;
}

.resumelink {
  font-size: 0.08em;
}

.thename{
  font-size: 0.2em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.09em;
}

.siteentry {
  font-size:0.04em;
}

}

@media screen and (max-width: 600px) {
.parent {
  max-width: 600px;
  margin-left:calc(50% - 300px);
}

.headlinetext{
  font-size:1em;
}

.middlebox {
  padding-left:6%;
}

.aboutdescription {
  font-size: 0.045em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

.theprojectname{
  font-size:0.085em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.07em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.045em;
}

.thelcock {
  font-size:0.06em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.08em;
}

.thename{
  font-size: 0.2em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.055em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.09em;
}

.aptitcatatitle {
  font-size: 0.1em;
}

.siteentry {
  font-size:0.035em;
}
}
@media screen and (max-width: 500px) {
.parent {
  max-width: 500px;
  margin-left:calc(50% - 250px);
}

.headlinetext{
  font-size:0.8em;
}

.aboutdescription {
  font-size: 0.042em;
}

.aboutdescriptionfinal {
  font-size: 0.042em;
}

.theprojectname{
  font-size:0.07em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.05em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linkbox1 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox1:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.resumedescription {
  font-size: 0.035em;
}

.linktext {
  font-size: 0.07em
}

.resumelink {
  font-size: 0.06em;
}

.thename{
  font-size: 0.12em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.025em;
}

.apatitentry {
  font-size:0.05em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.06em;
}

}

@media screen and (max-width: 400px) {
.parent {
  max-width: 400px;
  margin-left:calc(50% - 200px);
}

.headlinetext{
  font-size:0.8em;
}

.aboutdescription {
  font-size: 0.035em;
}

.aboutdescriptionfinal {
  font-size: 0.04em;
}

.theprojectname{
  font-size:0.06em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.04em;
}

.portdate{
  font-size: 0.03em;
}

.portdesctitle {
  font-size:0.05em;
}

.portdesctext {
  font-size:0.035em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.03em;
}

.linktext {
  font-size: 0.06em
}

.resumelink {
  font-size: 0.05em;
}

.thename{
  font-size: 0.1em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.02em;
}

.apatitentry {
  font-size:0.04em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.04em;
}

}

}

@media screen and (max-height: 800px) {

.parent {
  max-width: 1920px;
  margin-left:calc(50% - 960px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-10%) translateY(105%) scaleY(2.55) scaleX(0.56);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-10%) translateY(105%);
  }
  100% {
  transform: translateX(-10%) translateY(105%) scaleY(2.55) scaleX(0.56);
  }
}

.thename{
  font-size: 0.25em;
}

.porttitle{
  font-size: 0.1em;
}

.portfoliooptdate {
  padding-top:4%;
}

.portdate{
  font-size: 0.08em;
}

.contextbox {
  height:90%;
}

.portdesctitle {
  font-size:0.13em;
}

.portdesctext {
  font-size:0.08em;
}

.pagetext {
  font-size: 0.08em;
  }

.apatitentry {
  font-size:0.09em;
}

.apatitentrypage {
  font-size:0.1em;
}

.aptitcatatitle {
  font-size:0.13em;
}

.contecttrial {
  font-size: 0.3em;
}

.linktext {
  font-size:0.13em;
}

.detlinksbox {
  flex:3;
}

.linkbox1 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox1:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.descriptionone{
  font-size:0.1em;
  line-height: 0.7em;
}

.siteentry {
  font-size: 0.065em;
}

.aboutdescription {
  font-size: 0.08em;
}

.resumedescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.09em;
}

.resumelink {
  font-size: 0.12em;
}


.fillresumelink {
  font-size: 0.1em;
}

.thelcock {
  font-size:0.12em;
}

.theprojectname{
  font-size:0.2em;
}

.theprojectyears{
  font-size:0.1em;
}

.footertext {
 transform:translateY(-50%);
}
.footertext p {
 font-size: 4em;
}

@media screen and (max-width: 1900px) {
.parent {
  max-width: 1900px;
  margin-left:calc(50% - 950px);
}

.headlinetext{
  font-size:1.8em;
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-20%) translateY(115%) scaleY(2.1) scaleX(0.58);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-20%) translateY(115%);
  }
  100% {
  transform: translateX(-20%) translateY(115%) scaleY(2.1) scaleX(0.58);
  }
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.17em;
}

.linktext {
  font-size:0.1em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.1em;
}

.portdesctext {
  font-size:0.08em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
}

.resumedescription {
  font-size: 0.065em;
}

.resumelink {
  text-align: center;
  font-size: 0.11em;
}

.thename{
  font-size: 0.3em;
}
}

@media screen and (max-width: 1800px) {
.parent {
  max-width: 1800px;
  margin-left:calc(50% - 900px);
}

.titleitself {
  width:1em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-40%) translateY(123%) scaleY(2.2) scaleX(0.62);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-40%) translateY(123%);
  }
  100% {
  transform: translateX(-40%) translateY(123%) scaleY(2.2) scaleX(0.62);
  }
}

.linkbox1 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox1:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox2:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox3:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4 {
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.linkbox4:hover{
  width:80px;
  height:80px;
  background-size: 80px 80px;
  margin-left:calc(50% - 40px);
  margin-top:calc(50% - 80px);
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.16em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.1em;
}

.portdesctext {
  font-size:0.07em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
}

.resumedescription {
  font-size: 0.065em;
}

.resumelink {
  font-size: 0.11em;
}

.thename{
  font-size: 0.25em;
}

.siteentry {
  font-size: 0.065em;
}


}
@media screen and (max-width: 1700px) {
.parent {
  max-width: 1700px;
  margin-left:calc(50% - 850px);
}

.titleitself {
  width:1.2em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-30%) translateY(118%) scaleY(2.5) scaleX(0.6);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-30%) translateY(118%);
  }
  100% {
  transform: translateX(-30%) translateY(118%) scaleY(2.5) scaleX(0.6);
  }
}

.linkbox1 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox1:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.aboutdescription {
  font-size: 0.08em;
}

.aboutdescriptionfinal {
  font-size: 0.085em;
}

.theprojectname{
  font-size:0.15em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.11em;
}

.portdesctext {
  font-size:0.072em;
}

.portdate{
  font-size: 0.07em;
}

.thelcock {
  font-size:0.11em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.25em;
}
}

@media screen and (max-width: 1600px) {
.parent {
  max-width: 1600px;
  margin-left:calc(50% - 800px);
}

.titleitself {
  width:0.95em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-55%) translateY(125%) scaleY(1.9) scaleX(0.63);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-55%) translateY(125%);
  }
  100% {
  transform: translateX(-55%) translateY(125%) scaleY(1.9) scaleX(0.63);
  }
}

.aboutdescription {
  font-size: 0.07em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.15em;
}

.portdesctext {
  font-size:0.065em;
}

.portdesctitle {
  font-size:0.15em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.1em;
}

.portdate{
  font-size: 0.07em;
}

.resumedescription {
  font-size: 0.06em;
}

.resumelink {
  font-size: 0.11em;
}

.thename{
  font-size: 0.25em;
}
}

@media screen and (max-width: 1500px) {
.parent {
  max-width: 1500px;
  margin-left:calc(50% - 750px);
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-52%) translateY(117%) scaleY(1.8) scaleX(0.58);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-52%) translateY(117%);
  }
  100% {
  transform: translateX(-52%) translateY(117%) scaleY(1.8) scaleX(0.58);
  }
}

.aboutdescription {
  font-size: 0.065em;
}

.aboutdescriptionfinal {
  font-size: 0.08em;
}

.theprojectname{
  font-size:0.14em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.1em;
  line-height:1em;
}

.porttitle{
  font-size: 0.11em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.07em;
}

.portdate{
  font-size: 0.06em;
}

.thelcock {
  font-size:0.095em;
}

.resumedescription {
  font-size: 0.06em;
}

.resumelink {
  font-size: 0.11em;
}

.siteentry {
  font-size: 0.06em;
}

.apatitentry {
  font-size:0.08em;
}

}

@media screen and (max-width: 1400px) {
.parent {
  max-width: 1400px;
  margin-left:calc(50% - 700px);
}

.bigtitletext {
  font-size: 0.7em;
  transform: translateX(-53%) translateY(117%) scaleY(1.65) scaleX(0.59);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-53%) translateY(117%);
  }
  100% {
  transform: translateX(-53%) translateY(117%) scaleY(1.65) scaleX(0.59);
  }
}

.aboutdescription {
  font-size: 0.06em;
}

.aboutdescriptionfinal {
  font-size: 0.07em;
}

.theprojectname{
  font-size:0.13em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.095em;
}

.portdate{
  font-size: 0.06em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.07em;
}

.thelcock {
  font-size:0.09em;
}

.resumedescription {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.08em;
}

.thename{
  font-size: 0.3em;
}

.siteentry {
  font-size: 0.06em;
}

.headlinetext {
  font-size:1.3em;
}
}

@media screen and (max-width: 1300px) {
.parent {
  max-width: 1300px;
  margin-left:calc(50% - 650px);
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-55%) translateY(126%) scaleY(1.75) scaleX(0.63);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-55%) translateY(126%);
  }
  100% {
  transform: translateX(-55%) translateY(126%) scaleY(1.75) scaleX(0.63);
  }
}

.aboutdescription {
  font-size: 0.065em;
}

.aboutdescriptionfinal {
  font-size: 0.065em;
}

.theprojectname{
  font-size:0.12em;
}

.theprojectyears{
  font-size:0.08em;
}

.porttitle{
  font-size: 0.08em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdate{
  font-size: 0.05em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.06em;
}

.thelcock {
  font-size:0.085em;
}

.resumedescription {
  font-size: 0.05em;
}

.resumelink {
  font-size: 0.065em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.09em;
}

.apatitentrypage {
  font-size:0.095em;
}
}

@media screen and (max-width: 1200px) {
.contecttrial {
  font-size:0.2em;
}

.parent {
  max-width: 1200px;
  margin-left:calc(50% - 600px);
}

.titleitself {
  width:0.9em;
}

.bigtitletext {
  font-size: 0.65em;
  transform: translateX(-60%) translateY(125%) scaleY(1.8) scaleX(0.63);
}

@keyframes rotategetgot {
  0% {
    transform: rotateX(1200deg) translateX(-60%) translateY(125%);
  }
  100% {
  transform: translateX(-60%) translateY(125%) scaleY(1.8) scaleX(0.63);
  }
}

.aboutdescription {
  font-size: 0.05em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

.theprojectname{
  font-size:0.11em;
}

.theprojectyears{
  font-size:0.08em;
}

.portfoliooption {
  flex-direction: column;
}

.porttitle{
  font-size: 0.06em;
  line-height: 0.08em
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.06em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.05em;
}

.resumelink {
  font-size: 0.08em;
}

.siteentry {
  font-size: 0.05em;
}

.apatitentry {
  font-size:0.07em;
}

.apatitentrypage {
  font-size:0.07em;
}

.linktext {
  font-size:0.13em;
}

}

@media screen and (max-width: 1100px) {
.parent {
  max-width: 1100px;
  margin-left:calc(50% - 550px);
}

.leftbox{
  display:none;
}

.bigtoolbox {
  display:flex;
  flex:1;
  flex-direction: row;
}

.headlinetext{
  font-size:1.6em;
  text-align: center;
  color:black;
}

.middlebox {
  padding-left:5%;
  height:100%;
  flex:2;
}

.rightbox {
  height:100%;
  flex:1;
}

.aboutdescription {
  font-size: 0.045em;
}

.aboutdescriptionfinal {
  font-size: 0.06em;
}

.theprojectname{
  font-size:0.15em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.08em;
}

.portdesctext {
  font-size:0.06em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.05em;
}

.resumelink {
  font-size: 0.08em;
}

.siteentry {
  font-size: 0.06em;
}

.apatitentry {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.1em;
}
}

@media screen and (max-width: 1000px) {
.parent {
  max-width: 1000px;
  margin-left:calc(50% - 500px);
}

.headlinetext{
  font-size:1.6em;
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.055em;
}

.theprojectname{
  font-size:0.14em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.045em;
}

.resumelink {
  font-size: 0.07em;

}

.siteentry {
  font-size: 0.055em;
}

.apatitentry {
  font-size:0.08em;
}

.aptitcatatitle {
  font-size:0.12em;
}

.apatitentrypage {
  font-size:0.1em;
}

.headlinetext{
  font-size:1.1em;
}
}

@media screen and (max-width: 900px) {
.parent {
  max-width: 900px;
  margin-left:calc(50% - 450px);
}

.headlinetext{
  font-size:1em;
}

.aboutdescription {
  font-size: 0.05em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

p br {
  line-height:30px;
}

.theprojectname{
  font-size:0.12em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.06em;
}

.portdesctext {
  font-size:0.055em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.075em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.09em;
}

.apatitentrypage {
  font-size:0.08em;
}

.siteentry {
  font-size:0.05em;
}
}

@media screen and (max-width: 800px) {
.parent {
  max-width: 800px;
  margin-left:calc(50% - 400px);
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

.theprojectname{
  font-size:0.11em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.085em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.08em;
}

.linktext {
  font-size:0.1em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.05em;
}

.thename {
  font-size:0.2em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.11em;
}

.siteentry {
  font-size:0.045em;
}
}

@media screen and (max-width: 700px) {
.parent {
  max-width: 700px;
  margin-left:calc(50% - 350px);
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.05em;
}

.theprojectname{
  font-size:0.09em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.05em;
}

.thelcock {
  font-size:0.07em;
}

.linkbox1 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox1:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.resumedescription {
  font-size: 0.035em;
}

.resumelink {
  font-size: 0.05em;
}

.thename{
  font-size: 0.2em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.09em;
}

.siteentry {
  font-size:0.04em;
}
}

@media screen and (max-width: 600px) {
.parent {
  max-width: 600px;
  margin-left:calc(50% - 300px);
}
.footertext {
  font-size:0.6em;
  width:300%;
}
.headlinetext{
  font-size:1em;
}

.middlebox {
  padding-left:6%;
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.04em;
}

.theprojectname{
  font-size:0.085em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.065em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.06em;
}

.portdesctext {
  font-size:0.045em;
}

.thelcock {
  font-size:0.06em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.07em;
}

.thename{
  font-size: 0.2em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.07em;
}

.aptitcatatitle {
  font-size: 0.1em;
}

.siteentry {
  font-size:0.035em;
}
}
@media screen and (max-width: 500px) {

.parent {
  max-width: 500px;
  margin-left:calc(50% - 250px);
}

.headlinetext{
  font-size:0.8em;
}

.aboutdescription {
  font-size: 0.042em;
}

.aboutdescriptionfinal {
  font-size: 0.042em;
}

.theprojectname{
  font-size:0.07em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.05em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linkbox1 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox1:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.resumedescription {
  font-size: 0.035em;
}

.linktext {
  font-size: 0.07em
}

.resumelink {
  font-size: 0.06em;
}

.thename{
  font-size: 0.12em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.025em;
}

.apatitentry {
  font-size:0.05em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.06em;
}



}

@media screen and (max-width: 400px) {
.parent {
  max-width: 400px;
  margin-left:calc(50% - 200px);
}

.headlinetext{
  font-size:0.8em;
}

.aboutdescription {
  font-size: 0.032em;
}

.aboutdescriptionfinal {
  font-size: 0.032em;
}

.theprojectname{
  font-size:0.06em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.04em;
}

.portdate{
  font-size: 0.03em;
}

.portdesctitle {
  font-size:0.05em;
}

.portdesctext {
  font-size:0.035em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.03em;
}

.linktext {
  font-size: 0.06em
}

.resumelink {
  font-size: 0.05em;
}

.thename{
  font-size: 0.1em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.02em;
}

.apatitentry {
  font-size:0.04em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.04em;
}

}

}


@media screen and (max-height: 700px) {

.parent {
  max-width: 1100px;
  height:500px;
  margin-left:calc(50% - 350px);
}

.leftbox{
  display:none;
}

.bigtoolbox {
  display:flex;
  flex:1;
  flex-direction: row;
}

.headlinetext{
  font-size:1.4em;
}

.middlebox {
  padding-left:5%;
  height:100%;
  flex:2;
}

.rightbox {
  height:100%;
  flex:1;
}

.aboutdescription {
  font-size: 0.055em;
}

.aboutdescriptionfinal {
  font-size: 0.06em;
}

.theprojectname{
  font-size:0.15em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.08em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.06em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.1em;
}

.thename{
  font-size: 0.3em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.065em;
}

.apatitentry {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.1em;
}

@media screen and (max-width: 1000px) {
.parent {
  max-width: 1000px;
  margin-left:calc(50% - 500px);
}

.aboutdescription {
  font-size: 0.04em;
}

.aboutdescriptionfinal {
  font-size: 0.045em;
}

.theprojectname{
  font-size:0.14em;
}

.theprojectyears{
  font-size:0.1em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.1em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.09em;

}
.thename{
  font-size: 0.3em;
}

.siteentry {
  font-size: 0.05em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.1em;
}

.apatitentrypage {
  font-size:0.1em;
}

.headlinetext{
  font-size:1.1em;
}
}

@media screen and (max-width: 900px) {
.parent {
  max-width: 900px;
  margin-left:calc(50% - 450px);
}
.aboutdescription {
  font-size: 0.035em;
}

.aboutdescriptionfinal {
  font-size: 0.04em;
}

.theprojectname{
  font-size:0.12em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.09em;
  line-height:1em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.08em;
}

.linktext {
  font-size:0.08em;
}

.resumedescription {
  font-size: 0.04em;
}

.resumelink {
  font-size: 0.075em;
}

.thename{
  font-size: 0.2em;
}

.siteentry {
  font-size: 0.04em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.1em;
}

}

@media screen and (max-width: 800px) {
.parent {
  max-width: 800px;
  margin-left:calc(50% - 400px);
}

.aboutdescription {
  font-size: 0.03em;
}

.aboutdescriptionfinal {
  font-size: 0.04em;
}

.theprojectname{
  font-size:0.11em;
}

.theprojectyears{
  font-size:0.09em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.07em;
}

.portdesctext {
  font-size:0.035em;
}

.thelcock {
  font-size:0.08em;
}

.linktext {
  font-size:0.07em;
}

.resumedescription {
  font-size: 0.035em;
}

.resumelink {
  font-size: 0.06em;

}
.thename{
  font-size: 0.25em;
}

.siteentry {
  font-size: 0.06em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.07em;
}

.siteentry {
  font-size:0.045em;
}
}

@media screen and (max-width: 700px) {
.headlinetext{
  font-size:0.6em;
}

.parent {
  max-width: 700px;
  margin-left:calc(50% - 350px);
}

.aboutdescription {
  font-size: 0.03em;
}

.aboutdescriptionfinal {
  font-size: 0.03em;
}

.theprojectname{
  font-size:0.09em;
  line-height:1em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.06em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.06em;
}

.portdesctext {
  font-size:0.04em;
}

.thelcock {
  font-size:0.07em;
}

.linkbox1 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox1:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox2:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox3:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4 {
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.linkbox4:hover{
  width:60px;
  height:60px;
  background-size: 60px 60px;
  margin-left:calc(50% - 30px);
  margin-top:calc(50% - 60px);
}

.resumedescription {
  font-size: 0.035em;
}

.resumelink {
  font-size: 0.05em;
}

.thename{
  font-size: 0.2em;
}

.siteentry {
  font-size: 0.05em;
}

.apatitentry {
  font-size:0.06em;
}

.aptitcatatitle {
  font-size:0.07em;
}

.apatitentrypage {
  font-size:0.09em;
}

.siteentry {
  font-size:0.04em;
}
}

@media screen and (max-width: 600px) {
.parent {
  max-width: 600px;
  margin-left:calc(50% - 300px);
}
.middlebox {
  padding-left:6%;
}

.aboutdescription {
  font-size: 0.02em;
}

.aboutdescriptionfinal {
  font-size: 0.03em;
}

.theprojectname{
  font-size:0.08em;
}

.theprojectyears{
  font-size:0.08em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.05em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.04em;
}

.portdesctext {
  font-size:0.03em;
}

.thelcock {
  font-size:0.06em;
}

.resumedescription {
  font-size: 0.03em;
}

.resumelink {
  font-size: 0.08em;
}

.thename{
  font-size: 0.15em;
}

.siteentry {
  font-size: 0.03em;
}

.apatitentry {
  font-size:0.04em;
}

.aptitcatatitle {
  font-size:0.07em;
}

.apatitentrypage {
  font-size:0.05em;
}

.aptitcatatitle {
  font-size: 0.1em;
}
}
@media screen and (max-width: 500px) {

.parent {
  max-width: 500px;
  margin-left:calc(50% - 250px);
}

.aboutdescription {
  font-size: 0.02em;
}

.aboutdescriptionfinal {
  font-size: 0.02em;
}

.theprojectname{
  font-size:0.06em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.05em;
}

.portdate{
  font-size: 0.04em;
}

.portdesctitle {
  font-size:0.06em;
}

.portdesctext {
  font-size:0.03em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.linkbox1 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox1:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox2:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox3:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4 {
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.linkbox4:hover{
  width:40px;
  height:40px;
  background-size: 40px 40px;
  margin-left:calc(50% - 20px);
  margin-top:calc(50% - 40px);
}

.resumedescription {
  font-size: 0.03em;
}

.linktext {
  font-size: 0.055em;
}

.resumelink {
  font-size: 0.055em;
}

.thename{
  font-size: 0.12em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.025em;
}

.apatitentry {
  font-size:0.04em;
}

.aptitcatatitle {
  font-size:0.08em;
}

.apatitentrypage {
  font-size:0.06em;
}

p br {
  line-height:30px;
}

}

@media screen and (max-width: 400px) {
.parent {
  max-width: 400px;
  margin-left:calc(50% - 200px);
}

.aboutdescription {
  font-size: 0.025em;
}

.aboutdescriptionfinal {
  font-size: 0.025em;
}

.theprojectname{
  font-size:0.06em;
}

.theprojectyears{
  font-size:0.07em;
  line-height:0.8em;
}

.porttitle{
  font-size: 0.03em;
}

.portdate{
  font-size: 0.03em;
}

.portdesctitle {
  font-size:0.03em;
}

.portdesctext {
  font-size:0.025em;
}

.thelcock {
  font-size:0.08em;
  line-height: 1em;
}

.resumedescription {
  font-size: 0.02em;
}

.linktext {
  font-size: 0.035em
}

.resumelink {
  font-size: 0.05em;
}

.thename{
  font-size: 0.1em;
  line-height: 1em;
}

.siteentry {
  font-size: 0.015em;
}

.apatitentry {
  font-size:0.03em;
}

.aptitcatatitle {
  font-size:0.06em;
}

.apatitentrypage {
  font-size:0.04em;
}
}
}
